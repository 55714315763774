import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { HomeOne, News, Page, Services } from "./Menu";
import DropDown from "./DropDown";
import Logo from "../../assets/images/logo.png";

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const serviceItems = [
    {
      name: "Patent Prosecution",
      path: "/patent/patent-prosecution/",
      subtopics: [
        {
          name: "Patent Filing",
          path: "/patent/patent-prosecution/Patent Filing",
        },
        {
          name: "Patent Drafting",
          path: "/patent/patent-prosecution/Patent Drafting",
        },
        {
          name: "Patentability Search",
          path: "/patent/patent-prosecution/Patentability Search",
        },
        {
          name: "Patent Search (Design)",
          path: "/patent/patent-prosecution/Patent Search (Design",
        },
        {
          name: "Office Action Responses",
          path: "/patent/patent-prosecution/Office Action Responses",
        },
        {
          name: "Directed Patent Prosecution",
          path: "/patent/patent-prosecution/Directed Patent Prosecution",
        },
      ],
    },
    {
      name: "Patent Litigation",
      path: "/patent/patent-litigation/",
      subtopics: [
        {
          name: "Claim Charts",
          path: "/patent/patent-litigation/Claim Charts",
        },
        {
          name: "Validity Search",
          path: "/patent/patent-litigation/Validity Search",
        },
        {
          name: "Invalidity Search",
          path: "/patent/patent-litigation/Invalidity Search",
        },
        {
          name: "Inter-Party Review",
          path: "/patent/patent-litigation/Inter-Party Review",
        },
        {
          name: "Pre/Post Grant Opposition",
          path: "/patent/patent-litigation/Pre/Post Grant Opposition",
        },
      ],
    },
    {
      name: "IP Commercialization",
      path: "/patent/commercialization-property/",
      subtopics: [
        {
          name: "FTO Search",
          path: "/patent/commercialization-property/FTO Search",
        },
        {
          name: "Evidence of Use",
          path: "/patent/commercialization-property/Evidence of Use",
        },
        {
          name: "Inventor Watch",
          path: "/patent/commercialization-property/Inventor Watch",
        },
        {
          name: "Licensing Support",
          path: "/patent/commercialization-property/Licensing Support",
        },
        {
          name: "Patent Monitoring",
          path: "/patent/commercialization-property/Patent Monitoring",
        },
        {
          name: "Infringement Search",
          path: "/patent/commercialization-property/Infringement Search",
        },
        {
          name: "Patent Due Diligence",
          path: "/patent/commercialization-property/Patent Due Diligence",
        },
        {
          name: "Reverse Engineering",
          path: "/patent/commercialization-property/Reverse Engineering",
        },
        {
          name: "Patent Portfolio Ranking",
          path: "/patent/commercialization-property/Patent Portfolio Ranking",
        },
      ],
    },
    {
      name: "Market Research",
      path: "/patent/market-research/",
      subtopics: [
        {
          name: "Landscape Analysis",
          path: "/patent/market-research/Landscape Analysis",
        },
        {
          name: "State of Art Analysis",
          path: "/patent/market-research/State of Art Analysis",
        },
        {
          name: "Competitive Analysis",
          path: "/patent/market-research/Competitive Analysis",
        },
        {
          name: "Product Line Comparison",
          path: "/patent/market-research/Product Line Comparison",
        },
      ],
    },
    {
      name: "R&D Support",
      path: "/patent/rd-support/",
      subtopics: [
        {
          name: "Data Collection and Analysis",
          path: "/patent/rd-support/Data Collection and Analysis",
        },
        {
          name: "Prototype Development",
          path: "/patent/rd-support/Prototype Development",
        },
        {
          name: "Technical Writing",
          path: "/patent/rd-support/Technical Writing",
        },
        {
          name: "IP Management",
          path: "/patent/rd-support/Intellectual Property (IP) Management",
        },
      ],
    },
    {
      name: "Competitive Watch",
      path: "/patent/competitive-watch/",
      subtopics: [
        {
          name: "Product/Service Tracking",
          path: "/patent/competitive-watch/Product/Service tracking",
        },
        {
          name: "IP Monitoring",
          path: "/patent/competitive-watch/Intellectual Property Monitoring",
        },
        {
          name: "Competitor Analysis",
          path: "/patent/competitive-watch/Competitor Analysis",
        },
      ],
    },
  ];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  return (
    <>
      <header
        className={`main-header header__style__one ${variant ? variant : ""
          } header__sticky ${isSticky ? "fixed-header" : ""}`}
      >
        <div className="header-top-one">
          <div className="auto-container">
            <div className="top__inner">
              <div className="top__hrader__left">
                <ul>
                  <li>
                    <span>Address :</span>GS Road, Guwahati, Assam, India
                  </li>
                  <li>/</li>
                  <li>
                    <span>Email :</span>{" "}
                    <Link to="mailto:Info@maticresearch.com">
                      Info@maticresearch.com
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="top__hrader__right">
                <ul>
                  {/* <li>
                    <Link to="#">
                      <i className="icon-10"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-14"></i>
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="https://www.linkedin.com/in/matic-research-8073a9305?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                    >
                      <i className="icon-01"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icon-11"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box">
              <div className="logo-box">
                <figure>
                  <Link to="/">
                    <img src={Logo} width="75" height="60" alt="" />
                  </Link>
                </figure>
              </div>
              <div className="menu-area clearfix">
                <div
                  className={
                    mobileToggle ? "mobile-menu-visible" : "mobile-menus"
                  }
                >
                  <div
                    className="mobile-nav-toggler"
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <i className="icon-bar"></i>
                    <i className="icon-bar"></i>
                    <i className="icon-bar"></i>
                  </div>
                  <div className="mobile-menu">
                    <div className="menu-backdrop"></div>
                    <div
                      className="close-btn"
                      onClick={() => setMobileToggle(!mobileToggle)}
                    >
                      <i className="icon-fa-times"></i>
                    </div>

                    <nav className="menu-box">
                      <div className="nav-logo">
                        <Link to="/">
                          <img
                            src={Logo}
                            width="75"
                            height="60"
                            alt=""
                            title=""
                          />
                        </Link>
                      </div>
                      <div className="menu-outer">
                        <div
                          className="collapse navbar-collapse show clearfix"
                          id="navbarSupportedContent"
                        >
                          <ul className="navigation clearfix HomeOne-menu">
                            <li className="dropdown">
                              <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                              <NavLink to="/about">About</NavLink>
                            </li>
                            <li
                              className="services-dropdown"
                              style={{
                                color: "#fff",
                                cursor: "pointer",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                              onMouseOver={() => setHovered(true)}
                              onMouseOut={() => setHovered(false)}
                            >
                              <NavLink to="/patent/patent-prosecution">
                                Services <span className="arrow-down">▼</span>
                              </NavLink>
                              {hovered && (
                                <div
                                  className="dropdown"
                                  style={{
                                    width: "1500px",
                                    position: "absolute",
                                    left: "-600px",
                                    backgroundColor: "#00061a",
                                    zIndex: "9999",
                                    marginLeft: "70px",
                                    paddingLeft: "30px"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "50px",
                                    }}
                                    className="dropdown-grid"
                                  >
                                    {serviceItems.map((service, index) => (
                                      <div
                                        key={index}
                                        className="dropdown-column"
                                      >
                                        <p
                                          className="topicHeading"
                                          style={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                            marginBottom: "12px",
                                          }}
                                          onClick={() => navigate(service.path)}
                                          onMouseOver={(e) => {
                                            e.currentTarget.style.textDecoration =
                                              "underline";
                                            e.currentTarget.style.color =
                                              "#f5ea4e";
                                          }}
                                          onMouseOut={(e) => {
                                            e.currentTarget.style.textDecoration =
                                              "none";
                                            e.currentTarget.style.color =
                                              "inherit";
                                          }}
                                        >
                                          {service.name}
                                        </p>
                                        <ul>
                                          {service.subtopics.map(
                                            (subtopic, idx) => (
                                              <li
                                                key={idx}
                                                onClick={() =>
                                                  navigate(subtopic.path)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  // marginBottom: "5px",
                                                  fontFamily: "400",
                                                  fontSize: "14px",
                                                }}
                                                onMouseOver={(e) => {
                                                  e.currentTarget.style.textDecoration =
                                                    "underline";
                                                  e.currentTarget.style.color =
                                                    "#f5ea4e";
                                                }}
                                                onMouseOut={(e) => {
                                                  e.currentTarget.style.textDecoration =
                                                    "none";
                                                  e.currentTarget.style.color =
                                                    "inherit";
                                                }}
                                                className="subtopic-item"
                                              >
                                                {subtopic.name}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                              {/* <DropDown>
                                                                <ul>
                                                                <Services />
                                                                </ul>
                                                            </DropDown> */}
                            </li>
                            <li className="dropdown">
                              <NavLink to="/projects">Industry</NavLink>
                              {/* <DropDown>
                                                                <ul>
                                                                <Page />
                                                                </ul>
                                                            </DropDown> */}
                            </li>
                            {/* <li className="dropdown" >
                                                            <NavLink to="/blog">Resources</NavLink>
                                                            <DropDown>
                                                                <ul>
                                                                <News />
                                                                </ul>
                                                            </DropDown>
                                                        </li>   */}
                            <li>
                              <NavLink to="/contact">Contact</NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="contact-info">
                        <h4>Contact Info</h4>
                        <ul>
                          <li>GS Road, Guwahati, Assam</li>
                          <li>
                            <Link to="tel:+91-8295968888">+91-8295968888</Link>
                          </li>
                          <li>
                            <Link to="mailto:info@maticresearch.com">
                              info@maticresearch.com
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix HomeOne-menu">
                      <li className="dropdown">
                        <NavLink to="/">Home</NavLink>
                        {/* <DropDown>
                                                    <ul>
                                                        <HomeOne />
                                                    </ul>
                                                </DropDown> */}
                      </li>
                      <li>
                        <NavLink to="/about">About</NavLink>
                      </li>
                      <li
                        className="services-dropdown"
                        style={{
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        onMouseOver={() => setHovered(true)}
                        onMouseOut={() => setHovered(false)}
                      >
                        <NavLink to="/patent/patent-prosecution">
                          Services <span className="arrow-down">▼</span>
                        </NavLink>
                        {hovered && (
                          <div
                            className="dropdown"
                            style={{
                              width: "1500px",
                              position: "absolute",
                              left: "-600px",
                              backgroundColor: "#00061a",
                              zIndex: "9999",
                              marginLeft: "70px",
                              paddingLeft: "30px"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "50px",
                                backgroundColor: "#00061a",
                                zIndex: "9999",
                              }}
                              className="dropdown-grid"
                            >
                              {serviceItems.map((service, index) => (
                                <div key={index} className="dropdown-column">
                                  <p
                                    className="topicHeading"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "600",
                                      marginBottom: "12px",
                                    }}
                                    onClick={() => navigate(service.path)}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.textDecoration =
                                        "underline";
                                      e.currentTarget.style.color = "#f5ea4e";
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.textDecoration =
                                        "none";
                                      e.currentTarget.style.color = "inherit";
                                    }}
                                  >
                                    {service.name}
                                  </p>
                                  <ul>
                                    {service.subtopics.map((subtopic, idx) => (
                                      <li
                                        key={idx}
                                        onClick={() => navigate(subtopic.path)}
                                        style={{
                                          cursor: "pointer",
                                          // marginBottom: "5px",
                                          fontFamily: "400",
                                          fontSize: "14px",
                                        }}
                                        onMouseOver={(e) => {
                                          e.currentTarget.style.textDecoration =
                                            "underline";
                                          e.currentTarget.style.color =
                                            "#f5ea4e";
                                        }}
                                        onMouseOut={(e) => {
                                          e.currentTarget.style.textDecoration =
                                            "none";
                                          e.currentTarget.style.color =
                                            "inherit";
                                        }}
                                        className="subtopic-item"
                                      >
                                        {subtopic.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {/* <DropDown>
                                                                <ul>
                                                                <Services />
                                                                </ul>
                                                            </DropDown> */}
                      </li>
                      <li className="dropdown">
                        <NavLink to="/projects">Industry</NavLink>
                        {/* <DropDown>
                                                    <ul>
                                                        <Page />
                                                    </ul>
                                                </DropDown> */}
                      </li>
                      {/* <li className="dropdown" >
                                                <NavLink to="/blog">Resources</NavLink>
                                                <DropDown>
                                                    <ul>
                                                        <News />
                                                    </ul>
                                                </DropDown>
                                            </li>   */}
                      <li>
                        <NavLink to="/contact">Contact</NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="btn-box">
                <Link to="/contact" className="theme-btn theme-btn-one">
                  <i className="icon-02"></i>
                  <b>Collaborate or Work with us</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box">
              <div>
                <figure className="logo">
                  <Link to="/">
                    <img src={Logo} width="75" height="60" alt="" />
                  </Link>
                </figure>
              </div>
              <div className="menu-area clearfix">
                <nav className="main-menu clearfix">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix HomeOne-menu">
                      <li className="dropdown">
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                        <NavLink to="/about">About</NavLink>
                      </li>
                      <li
                        className="services-dropdown"
                        style={{
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        onMouseOver={() => setHovered(true)}
                        onMouseOut={() => setHovered(false)}
                      >
                        <NavLink to="/patent/patent-prosecution">
                          Services <span className="arrow-down">▼</span>
                        </NavLink>
                        {hovered && (
                          <div
                            className="dropdown"
                            style={{
                              width: "1500px",
                              position: "absolute",
                              left: "-600px",
                              backgroundColor: "#00061a",
                              zIndex: "9999",
                              paddingLeft: "8px",
                              marginLeft: "70px",
                              paddingLeft: "30px"
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "50px",
                                backgroundColor: "#00061a",
                                zIndex: "9999",

                              }}
                              className="dropdown-grid"
                            >
                              {serviceItems.map((service, index) => (
                                <div key={index} className="dropdown-column" >
                                  <p
                                    className="topicHeading"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: "600",
                                      marginBottom: "12px",
                                    }}
                                    onClick={() => navigate(service.path)}
                                    onMouseOver={(e) => {
                                      e.currentTarget.style.textDecoration =
                                        "underline";
                                      e.currentTarget.style.color = "#f5ea4e";
                                    }}
                                    onMouseOut={(e) => {
                                      e.currentTarget.style.textDecoration =
                                        "none";
                                      e.currentTarget.style.color = "inherit";
                                    }}
                                  >
                                    {service.name}
                                  </p>
                                  <ul>
                                    {service.subtopics.map((subtopic, idx) => (
                                      <li
                                        key={idx}
                                        onClick={() => navigate(subtopic.path)}
                                        style={{
                                          cursor: "pointer",
                                          // marginBottom: "5px",
                                          fontFamily: "400",
                                          fontSize: "14px",
                                        }}
                                        onMouseOver={(e) => {
                                          e.currentTarget.style.textDecoration =
                                            "underline";
                                          e.currentTarget.style.color =
                                            "#f5ea4e";
                                        }}
                                        onMouseOut={(e) => {
                                          e.currentTarget.style.textDecoration =
                                            "none";
                                          e.currentTarget.style.color =
                                            "inherit";
                                        }}
                                        className="subtopic-item"
                                      >
                                        {subtopic.name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {/* <DropDown>
                                                                <ul>
                                                                <Services />
                                                                </ul>
                                                            </DropDown> */}
                      </li>
                      <li className="dropdown">
                        <NavLink to="/projects">Industry</NavLink>
                      </li>
                      {/* <li className="dropdown" >
                                                <NavLink to="/blog">Resources</NavLink>
                                                <DropDown>
                                                    <ul>
                                                        <News />
                                                    </ul>
                                                </DropDown>
                                            </li>   */}
                      <li>
                        <NavLink to="/contact">Contact</NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="btn-box">
                <Link to="/contact" className="theme-btn theme-btn-one">
                  <i className="icon-02"></i>
                  <b>Get Search Proposal in 30 Minutes</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
