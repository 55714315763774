export const ClaimChart = () => {
    return (
      <div
        style={{
          background: "#00061A",
          color: "#fff",
          marginTop: "0px",
          padding: "0 50px",
        }}
      >
        <p
          style={{
            textAlign: "justify",
            margin: "0",
            fontSize: "20px",
          }}
        >
          <br /> 
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "30px" }}
          >Claim Chart
          </span>{" "}
          <br /> 
          <br /> 
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          >At Matic Research,
          </span>{" "}
          We understand the significance of patent claim charts as visual or structured representations, 
          presented either graphically or in tabular form, showcasing analyzed data within a patent claim. These charts essentially encapsulate all scrutinized information within a patent claim. Serving as invaluable tools, they aid in patent validation analysis and the assessment of potential
          infringement, enabling informed decisions for our clients.
          <br />
          <br />
          We specialize in a range of expertise, including Initial or preliminary infringement contentions 
          (PICs), domestic industry (DI) charts for ITC importation actions, expert claim charts for
          licensing negotiations, and design patent charts.
          <br />
          <br />
          These charts serve diverse purposes, including the PTO's reexamination during prosecution stages or 
          facilitating Patent Trial and Appeal Board (PTAB) assessments regarding a patent's validity.
          <br /> 
          <br /> 
          <span
            style={{ fontWeight: "bold", color: "#fff", fontSize: "25px" }}
          >Why you should choose Matic Research to prepare your Patent Claim Chart…?
          </span>{" "}
          <br /> 
          <br /> 
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔ 
          </span>{" "} We aid patent holder/owner/Plaintiff and their legal representatives by crafting various types of claim charts associated with Invalidations, Infringements, Prior Arts, and more. 
          <br />
         
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔ 
          </span>{" "} Tailored Reports: We offer solutions and adjustments aligned with customer needs and specific target areas. 
          <br /> 
          <span
          
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔ 
          </span>{" "} Delivering outputs at a highly competitive cost structure and with rapid turnaround times. 
          <br /> 
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔ 
          </span>{" "}  Employing a team of top-notch subject matter experts who ensure thorough and efficient detailed reporting.
          <br />
          <span
            style={{ fontWeight: "bold", color: "#ff0d76", fontSize: "20px" }}
          > ✔ 
          </span>{" "} Claim interpretations: Our team is an expert in precise interpretations of claim language.

          <br />
          <br />
          <br />
          <br />
          
        </p>
      </div>
    );
  };
  